* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  overflow-x: hidden;
}

@font-face {
  font-family: "pluto sans bold";
  src: url("/assets/fonts/PlutoSansBold.otf");
}

@font-face {
  font-family: "pluto sans dpd";
  src: url("/assets/fonts/PlutoSansDPDRegular.otf");
}

@font-face {
  font-family: "pluto sans extra light";
  src: url("/assets/fonts/PlutoSansExtraLight.otf");
}

@font-face {
  font-family: "pluto sans light dpd";
  src: url("/assets/fonts/PlutoSansDPDLight.otf");
}

@font-face {
  font-family: "pluto sans thin dpd";
  src: url("/assets/fonts/PlutoSansDPDThin.otf");
}

@font-face {
  font-family: "pluto sans light";
  src: url("/assets/fonts/PlutoSansLight.otf");
}

@font-face {
  font-family: "pluto sans";
  src: url("/assets/fonts/PlutoSansRegular.otf");
}

@font-face {
  font-family: "Tomatoes";
  src: url("/assets/fonts/Tomatoes.ttf");
}

.text-gradient {
  background: -webkit-linear-gradient(
    45deg,
    #df5d79 9.58%,
    #d05c89 29.57%,
    #b959a4 44.67%,
    #9d6eab 59.77%,
    #a59dd2 75.27%,
    #a3a9d9 87.92%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.green-text {
  background: linear-gradient(
    180deg,
    #8ac149 0%,
    #79b548 19.99%,
    #69a847 39.97%,
    #589c45 59.96%,
    #469044 79.95%,
    #328442 99.93%
  );
  -webkit-background-clip: text;
  background-clip: text;
}

.t100 {
  font-family: "pluto sans";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
}

.t200 {
  /* font-family: "pluto sans"; */
  font-family: "pluto sans extra light";
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 65px;
}

.t300 {
  font-family: "pluto sans extra light";
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  color: #414042;
  line-height: 65px;
}

.t400 {
  font-family: "pluto sans";
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 65px;
  line-height: 72px;
}

.t500 {
  font-family: "pluto sans";
  font-size: 48px;
  line-height: 65px;
  letter-spacing: -0.02em;
  color: #000000;
}
.b100 {
  font-family: "Tomatoes";
  font-style: normal;
  font-weight: normal;
  font-size: 34.5915px;
  line-height: 45px;
  letter-spacing: 0.02em;
}

@media screen and (max-width: 1035px) {
  .t100 {
    font-size: 26px;
  }
}

.body-text {
  font-family: "pluto sans light";
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  color: white;
}
.body-text-dark {
  font-family: "pluto sans light";
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
}

.image {
  height: auto;
  width: 100%;
}

.caption {
  font-family: "pluto sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18.9139px;
  line-height: 22px;
}

.card-heading {
  font-family: "Tomatoes";
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.02em;
}

.card-body {
  font-family: "pluto sans extra light";
  font-weight: 300;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}

@media screen and (max-width: 1150px) {
  .t200,
  .t300,
  .t400,
  .t500 {
    font-size: 22px;
    line-height: 21px;
  }

  .caption {
    font-size: 8px;
    line-height: 9px;
  }

  .b100 {
    font-size: 19px;
    line-height: 35px;
    letter-spacing: 0.02em;
  }

  .body-text {
    font-size: 13px;
    line-height: 16px;
  }

  .body-text-dark {
    font-size: 13px;
    line-height: 16px;
  }
}

@media screen and (max-width: 575px) {
  .card-heading {
    font-size: 20px;
    line-height: 35px;
  }

  .card-body {
    font-size: 10px;
    line-height: 16px;
  }
}

@media screen and (max-width: 320px) {
  .t200 {
    font-size: 18px;
  }
  .card-heading {
    font-size: 16px;
    line-height: 20px;
  }

  .card-body {
    font-size: 8px;
    line-height: 12px;
  }
}

br {
  content: " ";
}
br:after {
  content: " ";
}

.green {
  color: #8ac149;
}

.dud {
  color: #757575;
  font-family: "pluto sans thin dpd";
}
