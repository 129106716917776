.scene-1 {
  /* padding-top: 10rem; */
  /* background: linear-gradient(180deg, #0b0c0d 0%, #1f2526 50%, #0b0c0d 100%); */
  background: linear-gradient(180deg, #141718 0%, #293032 49.48%, #141614 100%);
}

.scene-1-container {
  padding: 4rem 0;
  padding-bottom: 0;
}

.scene-1-text {
  text-align: center;
  padding: 4rem 0;
  margin-bottom: 0;
}

.gx-3 {
  --bs-gutter-x: -24rem;
}

.first-element {
  margin-left: auto;
  margin-bottom: 1.5rem;
}

.last-element {
  margin-right: auto;
}

.middle-element {
  padding-bottom: 0.5rem;
}

.scene-2-img {
  /* height: auto;
  width: 100%; */
  /* margin-top: 1rem; */
  max-width: 290px;
}

/* .scene-2-container > div:nth-child(2) {
  padding-top: 1rem;
} */

@media screen and (max-width: 1399px) {
  .gx-3 {
    --bs-gutter-x: -13rem;
  }
}

@media screen and (max-width: 1199px) {
  .gx-3 {
    --bs-gutter-x: -3rem;
  }
}

@media screen and (max-width: 991px) {
  .gx-3 {
    --bs-gutter-x: -6rem;
  }
}

@media screen and (max-width: 767px) {
  .gx-3 {
    --bs-gutter-x: 1rem;
  }
}

@media screen and (max-width: 570px) {
  .gx-3 {
    margin: 0 1rem;
  }
}

.card {
  perspective: 1000px;
  background: transparent;
}
.card:hover .card__inner,
.card:active .card__inner,
.card:focus .card__inner {
  transform: rotateY(180deg);
}
.card,
.card__inner {
  position: relative;
  width: 290px;
  height: 395px;
  border-radius: 7.54459px;
  border: none !important;
}

.card__inner {
  transition: transform 0.25s ease-in-out;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
}
.card__back {
  transform: rotateY(180deg);
}
.card__front,
.card__back {
  border-radius: 7.54459px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 99.4%;
  height: 100%;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
}

.scene-2-img {
  height: 395px;
  width: 290px;
}

.card-heading {
  padding: 1rem;
}

.find-out-more {
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.54);
  margin-left: 0;
}

.card-body {
  padding: 0 2rem;
}

.stroke {
  -webkit-text-stroke: 1.5px white;
}

.card-1-bk,
.card-6-bk {
  background: #505560;
}
.card-2-bk {
  background: linear-gradient(180deg, #505560 0%, #29282d 100%);
}
.card-3-bk {
  background: linear-gradient(180deg, #505560 0%, #29282d 100%);
}
.card-4-bk {
  background: linear-gradient(
    180deg,
    #414042 0%,
    #232323 18.91%,
    #353842 50%,
    #1f1f20 80.4%,
    #0b0b0b 100%
  );
}
.card-5-bk {
  background: linear-gradient(180deg, #29282d 0%, #505560 100%);
}

@media screen and (max-width: 767px) {
  .card,
  .card__inner,
  .scene-2-img {
    width: 265px;
    height: 390px;
  }
}

@media screen and (max-width: 575px) {
  .scene-1 {
    /* padding-top: 3rem; */
  }
  .card,
  .card__inner,
  .scene-2-img {
    width: 155px;
    height: 225px;
  }
  .card-body {
    padding: 0 0.5rem;
  }
  .find-out-more {
    font-size: 10px;
    line-height: 16px;
  }
  .stroke {
    -webkit-text-stroke: 0.07rem white;
  }
  .first-element {
    margin-bottom: 1rem;
  }

  .centre {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 320px) {
  .card,
  .card__inner,
  .scene-2-img {
    width: 124px;
    height: 151px;
  }
  .find-out-more {
    font-size: 5px;
    line-height: 12px;
  }
  .card-heading {
    padding: 0.5rem;
  }
}
