.scene-6 {
  /* background: linear-gradient(180deg, #0b0c0d 0%, #1f2526 50%, #0b0c0d 100%); */
  background: #dedede;
}

.scene-6 > .container {
  padding-top: 10rem;
  padding-bottom: 2rem;
}

.scene-1-container {
  text-align: center;
}

.responsive-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.responsive-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.scene-6-text {
  padding: 4rem 0;
  text-align: center;
}

.trademark {
  width: 100%;
  font-family: "pluto sans";
  text-align: center;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: rgba(0, 0, 0, 0.55);
  padding-bottom: 10px;
}

.trademark > p {
  margin-bottom: 0;
}

@media screen and (max-width: 570px) {
  .scene-6 > .container {
    padding-top: 2rem;
    padding-bottom: 0;
  }

  .trademark {
    font-size: 11px;
    line-height: 19px;
    letter-spacing: -0.01em;
  }

  .scene-6-text {
    padding: 2rem 0;
    text-align: center;
  }
}
