.scene-3 {
  background: #e1e2e2;
  padding-top: 3rem;
  overflow-x: hidden;
  margin-bottom: -15rem;
}

.scene-3-img-col {
  padding-right: 0;
}

.scene-3-ipad {
  height: auto;
  width: 55vw;
}

.scene-3-text-col {
  align-self: center;
}

.scene-3-para {
  margin: 1.5rem 0;
}

.scene-3-text {
  padding: 1rem;
  margin-left: 4rem;
  margin-top: 3rem;
}

@media screen and (max-width: 1850px) {
  .scene-3-ipad {
    width: 135%;
  }

  .scene-3 {
    margin-bottom: -8rem;
  }
}

@media screen and (max-width: 1600px) {
  .scene-3-container {
    margin-right: 0;
  }

  .scene-3-ipad {
    width: 100%;
  }
}

@media screen and (max-width: 1400px) {
  .scene-3 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 575px) {
  .scene-3-text {
    margin-left: 0rem;
    margin-top: 1rem;
    margin-bottom: 0rem;
    text-align: center;
  }

  .scene-3-text-col {
    padding: 0 5%;
  }

  .scene-3 {
    padding-top: 2rem;
  }
}
