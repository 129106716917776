.scene-5 {
  background: linear-gradient(180deg, #141718 0%, #293032 49.48%, #141614 100%);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.green-laptop {
  height: auto;
  width: 56vw;
  position: relative;
  bottom: -10%;
}

.scene-5-row {
  height: 45vw;
}

.plug-container {
  width: 45%;
  position: relative;
  bottom: 175px;
}

.plug {
  width: 100%;
  height: auto;
}

.plug-mobile {
  display: none;
}

@media screen and (max-width: 1900px) {
  .plug-container {
    bottom: 100px;
  }
}

@media screen and (max-width: 1600px) {
  .scene-5-container {
    margin-right: 0;
  }

  .green-laptop {
    width: 110%;
    right: 8%;
    /* bottom: 18%; */
  }
}

@media screen and (max-width: 1500px) {
  .plug-container {
    bottom: 50px;
  }
}

@media screen and (max-width: 1400px) {
  .plug-container {
    width: 40%;
    bottom: 25px;
  }
}

@media screen and (max-width: 1200px) {
  .green-laptop {
    right: 5%;
  }
  .plug-container {
    width: 45%;
    bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  .green-laptop {
    /* bottom: 30%; */
  }
}

@media screen and (max-width: 769px) {
  .green-laptop {
    width: 45vw;
    /* bottom: 35%; */
    right: -15%;
  }

  .scene-5-row {
    height: 35vw;
  }
}

@media screen and (max-width: 735px) {
  .green-laptop {
    width: 110%;
    right: 5%;
  }
}

@media screen and (max-width: 665px) {
  .scene-5-row {
    height: 45vw;
  }
}

@media screen and (max-width: 570px) {
  .scene-5-row {
    flex-direction: column-reverse;
    height: auto;
  }

  .scene-5 {
    padding-top: 0;
  }

  .scene-5-col-text {
    padding: 0 12%;
  }

  .green-laptop {
    /* top: -75px; */
    top: -30px;
    width: 100%;
    right: -5%;
  }

  .plug {
    display: none;
  }
  .plug-mobile {
    display: block;
  }

  .plug-container {
    width: 100%;
  }
  .scene-5-green-title {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .scene-5-img-col {
    max-height: 350px;
  }

  .green-laptop {
    top: -30px;
  }
}

@media screen and (max-width: 375px) {
  .scene-5-img-col {
    max-height: 325px;
  }
}

@media screen and (max-width: 320px) {
  .scene-5-img-col {
    max-height: 270px;
  }
}
