.scene-4 {
  background: linear-gradient(180deg, #141718 0%, #293032 49.48%, #141614 100%);
  padding-top: 4rem;
}

.scene-4-container-2 {
  padding: 7rem 0;
}

.scene-4-container {
  padding: 4rem 0;
  margin-left: 0;
  margin-right: 0;
  max-width: 100vw;
  background: #dedede;
}

.iphone-container {
  position: relative;
}

.scene-4-iphone {
  height: auto;
  width: 75%;
}

.arrow-container {
  position: absolute;
  display: flex;
  width: 200%;
  left: 75%;
  bottom: 5%;
  align-items: center;
}

.arrow {
  height: 20px;
  width: 100%;
  max-width: 87px;
  margin-bottom: 1rem;
}

.scene-4-text {
  margin-bottom: 0;
  padding: 1rem;
}

.ipad {
  height: auto;
  width: 90%;
  position: relative;
  top: 20%;
  left: 10%;
}

.scene-4-text-col {
  padding: 0 7rem;
  margin-top: 5rem;
}

.scene-4-iphone-2 {
  height: auto;
  width: 100%;
}

.scene-4-text-2 {
  padding: 1rem 0;
  position: relative;
  right: 3rem;
}

.scene-4-para-2 {
  width: 60%;
}

.scene-4-text-container {
  margin-left: 2rem;
}

@media screen and (max-width: 1800px) {
  .scene-4-text-col {
    padding: 0 4rem;
  }
}

@media screen and (max-width: 1600px) {
  .scene-4-iphone {
    width: 80%;
  }

  .arrow-container {
    left: 77%;
  }

  .ipad {
    width: 80%;
    left: 21%;
  }

  .scene-4-text-col {
    padding: 0 1rem;
    margin-top: 0;
  }
  .scene-4-container-2 {
    padding: 0;
  }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1300px) {
  .arrow {
    transform: rotate(180deg);
  }
  .arrow-container {
    bottom: -5%;
  }
}

@media screen and (max-width: 1200px) {
  .scene-4-text-2 {
    right: 2rem;
  }
}

@media screen and (max-width: 1165px) {
  .arrow {
    transform: unset;
  }
  .arrow-container {
    bottom: 5%;
  }
}

@media screen and (max-width: 767px) {
  .scene-4-text-container {
    margin-left: 0rem;
  }
  .scene-4-text-2 {
    padding: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .scene-4-ipad-col {
    display: none;
  }
  .scene-4-text-col {
    padding-left: 0;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 570px) {
  .scene-4 {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .scene-4-container {
    padding-top: 2rem;
  }

  .arrow {
    height: 12px;
    width: 100%;
    max-width: 40px;
    transform: rotate(40deg);
    margin-bottom: 2rem;
  }

  .arrow-container {
    width: 100%;
    left: 80%;
    bottom: -10%;
  }

  .scene-4-text {
    line-height: 28px;
    padding-left: 0.5rem;
  }

  .scene-4-row-2 {
    flex-direction: row-reverse;
  }

  .scene-4-para-2 {
    width: 80%;
  }

  .scene-4-text-2 {
    right: 0;
    padding-left: 0;
    line-height: 28px;
  }

  .scene-4-text-container {
    margin-left: 0rem;
  }

  .scene-4-iphone-2 {
    position: relative;
    width: 120%;
    right: 20%;
  }
}

@media screen and (max-width: 375px) {
  /* .arrow-container {
    bottom: -25%;
  } */
}

@media screen and (max-width: 320px) {
  .arrow {
    margin-bottom: 4rem;
  }

  .arrow-container {
    bottom: -20%;
  }
}
