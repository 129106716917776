.scene-5-container-2 {
  padding-top: 10rem;
}

.scene-5-text {
  padding: 1rem;
  text-align: center;
  margin-right: -3rem;
  cursor: pointer;
}

.relove-1 {
  color: #899090;
}

.relove-2 {
  color: #159d15;
}

.scene-5-arrow-container {
  position: relative;
}

.scene-5-arrow {
  position: absolute;
  bottom: 35%;
  left: 115%;
}

.scene-5-para {
  margin-left: 0px;
  margin-top: 15px;
}

.scene-5-iphone {
  height: auto;
  width: 80%;
  margin-left: 10%;
}

.scene-5-relove {
  height: auto;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .scene-5-text {
    margin-right: -2rem;
  }
}

@media screen and (max-width: 990px) {
  .scene-5-arrow {
    width: 45%;
    height: auto;
  }
}

@media screen and (max-width: 570px) {
  .scene-5-container-2 {
    padding-top: 3rem;
  }
  .scene-5-text {
    margin-right: 0rem;
  }

  .scene-5-row-2 {
    flex-direction: row-reverse;
  }

  .scene-5-col-text-2 {
    width: 55%;
    text-align: center;
    margin-left: 0.5rem;
  }

  .scene-5-iphone {
    position: relative;
    width: 140%;
    left: -30px;
  }
  .scene-5-arrow {
    bottom: 100%;
    left: -10%;
    z-index: 100;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 40%;
  }

  .scene-5-relove {
    width: 50%;
  }

  .scene-5-para-col {
    padding-left: 3rem;
  }
}

@media screen and (max-width: 320px) {
  .scene-5-col-text-2 {
    margin-left: 0rem;
  }
}
